import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import Grid from '@mui/material/Grid'

import AccountProfile from './AccountProfile'
import AccountDetails from './AccountDetails'
import { RoleCard } from '@components'

const PREFIX = 'index'

const classes = {
	root: `${PREFIX}-root`,
	roleContainer: `${PREFIX}-roleContainer`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: theme.spacing(4),
	},

	[`& .${classes.roleContainer}`]: {
		paddingTop: theme.spacing(4),
	},
}))

const ProfileContent = ({ auth, clinician, updateClinicalSettings }) => {
	const roles = auth.app_metadata?.roles
	const title = auth.user_metadata?.title

	return (
		<Root className={classes.root}>
			<Grid container spacing={4}>
				<Grid item lg={4} md={6} xl={4} xs={12}>
					<Grid>
						<AccountProfile />
					</Grid>
					{roles && roles.length ? (
						<Grid item className={classes.roleContainer}>
							<RoleCard
								clinician={clinician?.admin}
								title={title}
								toggleVisibility={updateClinicalSettings}
							/>
						</Grid>
					) : (
						<></>
					)}
				</Grid>
				<Grid item lg={8} md={6} xl={8} xs={12}>
					<AccountDetails />
				</Grid>
			</Grid>
		</Root>
	)
}

ProfileContent.propTypes = {
	clinician: PropTypes.shape({
		admin: PropTypes.shape({
			key: PropTypes.string,
			is_enrolling: PropTypes.number,
		}),
	}),
	updateClinicalSettings: PropTypes.func.isRequired,
}

const mapStateToProps = ({ clinician, auth }) => {
	return {
		clinician,
		auth,
	}
}

export default connect(mapStateToProps, actions)(ProfileContent)
