import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import moment from 'moment'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'

const PREFIX = 'AccountProfile'

const classes = {
	root: `${PREFIX}-root`,
	details: `${PREFIX}-details`,
	avatar: `${PREFIX}-avatar`,
	progress: `${PREFIX}-progress`,
	uploadButton: `${PREFIX}-uploadButton`,
	actionContainer: `${PREFIX}-actionContainer`,
}

const StyledCard = styled(Card)(({ theme }) => ({
	[`&.${classes.root}`]: {},

	[`& .${classes.details}`]: {
		display: 'flex',
	},

	[`& .${classes.avatar}`]: {
		marginBottom: theme.spacing(1),
		height: 100,
		width: 100,
		flexShrink: 0,
		flexGrow: 0,
	},

	[`& .${classes.progress}`]: {
		marginTop: theme.spacing(2),
	},

	[`& .${classes.uploadButton}`]: {
		marginRight: theme.spacing(2),
	},

	[`& .${classes.actionContainer}`]: {
		justifyContent: 'flex-end',
	},
}))

const AccountProfile = (props) => {
	const { auth, className, ...rest } = props

	return (
		<StyledCard className={classNames(classes.root, className)}>
			<CardContent>
				<div className={classes.details}>
					<div>
						<Avatar className={classes.avatar} src={auth.picture} />
						<Typography gutterBottom variant="h4">
							{auth.name || auth.nickname}
						</Typography>
						<Typography className={classes.locationText} color="textSecondary" variant="body1">
							{auth.user_metadata?.geoip?.city_name}, {auth.user_metadata?.geoip?.country_code3}
						</Typography>
						<Typography className={classes.dateText} color="textSecondary" variant="body1">
							{moment().format('hh:mm A')} ({auth.user_metadata?.geoip?.time_zone})
						</Typography>
					</div>
				</div>
				<div className={classes.progress}>
					<Typography gutterBottom variant="body1">
						Profile Completeness: 70%
					</Typography>
					<LinearProgress value={70} variant="determinate" />
				</div>
			</CardContent>
			<Divider />
			<CardActions className={classes.actionContainer}>
				<Button variant="text">Remove picture</Button>
				<Button className={classes.uploadButton} variant="contained">
					Upload picture
				</Button>
			</CardActions>
		</StyledCard>
	)
}

AccountProfile.propTypes = {
	className: PropTypes.string,
}

const mapStateToProps = ({ auth }) => {
	return {
		auth,
	}
}

export default connect(mapStateToProps, actions)(AccountProfile)
